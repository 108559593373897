"use client";
import React, { forwardRef, useImperativeHandle } from "react";
import { FaSearch } from "react-icons/fa";
import styles from "./Navbar.module.css";
import Link from "next/link";
import Image from "next/image";
import { useUser } from "@hooks/Auth/useUser";
import Cookies from "js-cookie";
import { GenerateReceipt, getAllEvents } from "@services/eventAPI";
import { useEffect, useState } from "react";
import CountryList from "@components/CountryList/CountryList";
import LoginDropdown from "@components/LoginDropdown/LoginDropdown";
import { BiUser } from "react-icons/bi";
import { Spinner } from "@components/Spinner/Spinner";
import NewSearchBar from "@layout/CustomSearch/NewSearch";
import { isMobile } from "react-device-detect";
import JSZip from "jszip";
// import createPDF from "../app/utils/common/pdf";
import { saveAs } from "file-saver";
import { sendGAEvent, sendGTMEvent } from "@next/third-parties/google";
import { trackGAEvent } from "@lib/gtag";
import LoginDialog from "@components/LoginComponents/loginDialog/loginDialog";
import { useRouter } from "next/navigation";
import { useLocation } from "@hooks/useLocation";
// import useAdjust from "@hooks/useAdjust";
// import { trackAdjustEvent } from "@utils/adjust";
// import Adjust from "@adjustcom/adjust-web-sdk";

const Navbar = forwardRef(({ onSearchIconClick, allEvents = [], utm_source="", eventID="" }, ref) => {
  const router = useRouter();
  const [cookiesUpdated, setCookiesUpdated] = useState(0);
  const { user, tokens, token } = useUser();
  const {location} = useLocation();
  
  // // console.log({ user });
  useEffect(() => {
    const interval = setInterval(() => {
      const userCookie = Cookies.get("user");
      const tokensCookie = Cookies.get("tokens");

      if (!userCookie || !tokensCookie) {
        setCookiesUpdated(prev => prev + 1); // Trigger re-render
      }
    }, 1000); // Check every second (adjust as needed)

    return () => clearInterval(interval); // Clean up interval
  }, []);

  // // Remove cookies if necessary
  // useEffect(() => {
  //   if (user && Object.keys(user).length > 0 && !token) {
  //     Cookies.remove("user");
  //     Cookies.remove("tokens");
  //     setCookiesUpdated(prev => prev + 1); // Force re-render after removal
  //   }
  // }, [user, token]);

  const fName = user?.fname;
  // // console.log({ allEvents });
  const [uniqueEventCountries, setUniqueEventCountries] = useState([]);
  const [loginLoading, setLoginLoading] = useState(false);
  useEffect(() => {
    if (allEvents.length > 0) {
      const eventCountries = allEvents.map((event) => event.eventCountry);
      const uniqueEventCountriesSet = Array.from(new Set(eventCountries));
      setUniqueEventCountries(uniqueEventCountriesSet);
    }
  }, [allEvents]);
  // // console.log(uniqueEventCountries);
  const selectedCountry = (Cookies.get("selectedCountry") || "").toLowerCase();
  const capitalizedCountry =
    selectedCountry.substring(0, 1).toUpperCase() +
    selectedCountry.substring(1);

  const isServer = typeof window === "undefined";
  // const { trackEvent } = useAdjust("3plp4ofteam8");

  // useEffect(() => {
  //   trackEvent("page_view");
  // }, []);

  // const handleButtonClick = () => {
  //   Adjust.trackEvent({
  //     eventToken: "YOUR_EVENT_TOKEN", // Replace with your event token
  //   });
  // };

  // // console.log({ uniqueEventCountries });
 
  // -------------- New Login Flow --------------

  const [newLogin, setNewLogin] = useState(false);
  const CloseLogin = () => {
    setNewLogin(false)
  }
  useImperativeHandle(ref, () => ({
    toggleState: () => setNewLogin((prev) => !prev),
  }));
  const SuccessClose = (data, token) => {

    Cookies.set(
      "user",
      JSON.stringify({
        id: data.uid,
        email: data.email,
        idToken: token,
        refreshToken: token,
        fname: data.firstName,
        lastName: data.lastName,
        country: data.address,
        phoneNumber: data.phoneNumber,
        gender: data.gender,
        dob: data.dob,
        updatedFields: data.updatedFields,
        username: data.userName,
        isPhoneVerified: data.isPhoneVerified,
        isEmailVerified: data.isEmailVerified,
      }),
      {
        expires: 365, // Keep cookie for 1 year
        secure: true, // Optional: only send over HTTPS
        sameSite: "Lax", // Optional: protects against CSRF in most cases
      }
    );
    Cookies.set("token", JSON.stringify(token),   {
      expires: 365, // Keep cookie for 1 year
      secure: true, // Optional: only send over HTTPS
      sameSite: "Lax", // Optional: protects against CSRF in most cases
    });

    sendGTMEvent({ event: 'Sign In', value: 'Email' })

    Cookies.set(
      "tokens",
      JSON.stringify({
        idToken: token,
        refreshToken: token,
      }),
      {
        expires: 365, // Keep cookie for 1 year
        secure: true, // Optional: only send over HTTPS
        sameSite: "Lax", // Optional: protects against CSRF in most cases
      }
    );
    // router.reload();
    router.refresh();
    // setNewLogin(false)
    // window.location.reload()

  }

  return (
    <>
   {newLogin && <LoginDialog CloseLogin={CloseLogin} SuccessClose={SuccessClose}/>}
    <nav className={styles.navbar}>
      <div className={styles.left}>
        <Link
          href={isServer ? "/" : `/${capitalizedCountry}`}
          className={styles.logo}
          prefetch={false}
        >
          <div className={styles.overlayContainer}>
            <Image
              src="/assets/images/logo.png"
              alt="Tick'it Logo"
              width={65}
              height={65}
              priority={true}
            />
          </div>
        </Link>
        {/* <button
          onClick={() => {
            trackGAEvent("testadsa", "tesasdt", "tedasst");
            // trackAdjustEvent("aef8ch", { key: "test1", value: "event1" });
            Adjust.trackEvent({
              eventToken: "aef8ch", // Replace with your event token
            });
          }}
        >
          try
        </button> */}
        {allEvents.length > 0 && (
          <>
            {isMobile ? (
              <div className={styles.searchContainer}>
                <FaSearch
                  className={styles.searchIcon}
                  onClick={onSearchIconClick}
                />
            
              </div>
            ) : (
              <NewSearchBar allEvents={allEvents} />
            )}

            {/* <div>
              <CountryList list={uniqueEventCountries} />
            </div> */}
          </>
        )}
      </div>
      <div className={styles.right}>
        <div>
          <Link href="/organizer" prefetch={false}>
            <button className={`${styles.loginButton}`}>Organizer</button>
          </Link>
        </div>
        <div className={styles.buttonContainer}>
          <div>
            {!user || Object.keys(user).length === 0 ? (

              // <Link
              //   href="/login"
              //   prefetch={false}
              //   onClick={() => {
              //     setLoginLoading(true);
              //   }}
              // >
                <button className={styles.loginButton_1} onClick={() => setNewLogin(true)}>

                  {loginLoading ? <Spinner /> : <BiUser />}
                </button>
            ) : (
              <LoginDropdown user={user} tokens={tokens}/>
            )}
          </div>
        </div>
      </div>
    </nav>
    </>
  );
});

export default Navbar;
